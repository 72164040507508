<template>
  <f7-page class="register-page">
    <f7-navbar>
      <!--  <a slot="left" @click="back();"><font-awesome-icon :icon="['far', 'arrow-left']" fixed-width /></a> -->
    </f7-navbar>

    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/code.svg" alt="Mobile Authentication" loading="lazy" />

        <div class="info">
          <h2>{{ $t.getTranslation("LBL_STEP_2_OF_3") }}</h2>
          <h1>{{ $t.getTranslation("LBL_MOBILE_VERIFICATION") }}</h1>

          <p>
            {{ $t.getTranslation("LBL_MOBILE_VERIFICATION_SUB") }}
            <strong>{{ $h.formatMobile(formData) }}</strong>
            <f7-link class="no-ripple" @click="changeMobileNumber">{{ $t.getTranslation("LBL_CHANGE") }}</f7-link>
          </p>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.VerificationCode"
            name="VerificationCode"
            :required="validationRules?.VerificationCode?.required"
            :minlength="validationRules?.VerificationCode?.minimumLength"
            :maxlength="validationRules?.VerificationCode?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_VERIFICATION')"
            :placeholder="$t.getTranslation('LBL_MOBILE_VERIFICATION_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_VERIFICATION_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="tel"
            validate
            clear-button
            acceptinput="mobilenumber"
          >
          </f7-list-input>
        </f7-list>

        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
          {{ $t.getTranslation("LBL_CONTINUE") }}
        </f7-button>
        <f7-button large preloader :loading="isResendButtonProcessing" :disabled="isResendButtonProcessing || isDisableResendButton" @click="resend">
          {{ resendButtonText }}
        </f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject } from "vue";
import { Dom7 } from "framework7";

import { $HTTP } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default defineComponent({
  name: "RegisterMobileVerificationPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const isButtonProcessing = ref(false);
    const isResendButtonProcessing = ref(false);
    const countdown = ref(0);

    const formData = reactive({
      MobileCode: "",
      MobileNumber: "",
      VerificationCode: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      let registerInfo = store.getters["register/getData"];
      for (let keys in formData) {
        formData[keys] = registerInfo[keys];
      }

      Dom7("li[acceptinput='mobilenumber'] input").on("keypress", (e) => {
        let regex = new RegExp("^[0-9]+$");
        let character = String.fromCharCode(!e.charCode ? e.which : e.charCode);

        if (!regex.test(character)) {
          e.preventDefault();
        }
      });
    });

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const checkAllowNextOTPRequest = () => {
      let registerInfo = store.getters["register/getData"];
      let newUnixTime = new Date().getTime() / 1000;

      if (registerInfo && (formData.MobileCode == "" || registerInfo.MobileCode == formData.MobileCode) && (formData.MobileNumber == "" || registerInfo.MobileNumber == formData.MobileNumber)) {
        if (newUnixTime <= registerInfo.OTPNextRequest) {
          let countDownInterval = setInterval(() => {
            let newUnixTime = new Date().getTime() / 1000;
            let distance = (registerInfo.OTPNextRequest - newUnixTime) * 1000;

            if (distance > 0) {
              let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              let seconds = Math.floor((distance % (1000 * 60)) / 1000);

              countdown.value = `${minutes}:${helpers.padStart(seconds, 2, "0")}`;
            } else {
              countdown.value = 0;
              clearInterval(countDownInterval);
            }
          }, 1000);
        } else {
          countdown.value = 0;
          return true;
        }
      } else {
        countdown.value = 0;
        return true;
      }
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);
      return !isValid;
    });

    const isDisableResendButton = computed(() => {
      checkAllowNextOTPRequest();

      return countdown.value != 0;
    });

    const resendButtonText = computed(() => {
      return countdown.value != 0 ? `Resend Code - ${countdown.value}` : "Resend Code";
    });

    const changeMobileNumber = () => {
      store.dispatch("register/setData", {
        IsOTPGenerated: 0,
      });

      props.f7router.navigate("/register/mobileauthentication/");
    };

    const back = () => {
      props.f7router.back({ force: true });
    };

    const next = async () => {
      try {
        let isValid = validate(true);

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();

          let res = await $HTTP.post("/mobile/register/otp/verify", {
            OTP: formData.VerificationCode,
            MobileCode: formData.MobileCode,
            MobileNumber: formData.MobileNumber,
          });

          if (res && res.status === 200 && res.data && res.data.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            let returnData = res.data.data;
            if (returnData && returnData.isOTPVerified) {
              store.dispatch("register/setData", {
                ...formData,
                IsOTPVerified: 1,
              });

              props.f7router.navigate("/register/primary/");
              return;
            }
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    const resend = async () => {
      try {
        isResendButtonProcessing.value = true;
        helpers.showLoader();

        let res = await $HTTP.post("/mobile/auth/check/mobile", formData);

        if (res && res.status === 200 && res.data && res.data.data) {
          isResendButtonProcessing.value = false;
          helpers.hideLoader();

          let returnData = res.data.data;

          if (returnData && returnData.isRegistered) {
            helpers.createNotification({
              type: "info",
              title: $t.getTranslation("LBL_INFO"),
              message: $t.getTranslation("LBL_INFO_MOBILE_IN_USED"),
            });
          } else if (returnData && returnData.isRegistered === false) {
            let res = await $HTTP.post("/mobile/register/otp/generate", formData);

            if (res && res.status === 200 && res.data && res.data.data) {
              let returnData = res.data.data;
              if (returnData && returnData.isOTPGenerated) {
                store.dispatch("register/setData", {
                  ...formData,
                  IsOTPGenerated: 1,
                  OTPNextRequest: returnData.OTPNextRequest,
                });

                helpers.createNotification({
                  type: "info",
                  title: $t.getTranslation("LBL_INFO"),
                  message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_RESENT_CODE"),
                });

                return;
              }
            }
          }
        }

        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        isResendButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return {
      formData,
      validationRules,
      resendButtonText,
      isDisableNextButton,
      isDisableResendButton,
      changeMobileNumber,
      next,
      isButtonProcessing,
      resend,
      isResendButtonProcessing,
      back,
    };
  },
});
</script>
<style scoped>
p a {
  margin-left: 5px;
  text-decoration: underline;
}
</style>
